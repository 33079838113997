/* Main.js file */

/* All other vendor script should be loaded atm */
/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
var getClosest = function (elem, selector) {


  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { }
        return i > -1;
      };
  }

  // Get closest match
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

(function () {
  'use strict';
  
  document.addEventListener("DOMContentLoaded", function () {
    // what to do on document content load
    window.onscroll = function (e) {
      //do that
    }

    window.onresize = function (e) {
      //do that
    }
  });

  var tiltbox = document.querySelector(".tiltbox");
  VanillaTilt.init(tiltbox, {
    max: 25,
    speed: 1000
  });
  
})();
