/* soundmachine.js file */

(function () {
    'use strict';

    if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1); 
            return null;
        };
    }

    var playingAudio = [];

    var playSound = function (elem) {
        if (playingAudio.length > 0) {
            playingAudio[0].pause();
            playingAudio[0].currentTime = 0;
            playingAudio[0].load();
        }

        elem.play();
        playingAudio.length = 0;
        playingAudio.push(elem);
    }

    var allSounds = document.querySelectorAll('.soundmachine-audio');

    for (var i = 0; i < allSounds.length; i++) {
        let sound = allSounds[i];
        var button = sound.parentNode.querySelector('button');
        button.addEventListener('click', function (e) {
            if (!e.target.classList.contains('empty')) {
                e.target.classList.add('is-clicked');
                playSound(sound);

                setTimeout(function () {
                    e.target.classList.remove('is-clicked');
                },
                    100);
            }
        });
    }

    // The info tooltip that is shown as soon as a user clicks on an not yet available sound

    var emptyButtons = document.querySelectorAll('button.empty');
    var tooltipFB = document.querySelector('.tooltip--facebookhint');
    var tooltipFBArrow = tooltipFB.getElementsByClassName('arrow')[0];

    var tooltipTimer;

    var soundmachineContainer = document.querySelector('.soundmachine');

    console.log(emptyButtons.length);
    console.log(tooltipFBArrow);

    for (var j = 0; j < emptyButtons.length; j++) {

        let emptyButton = emptyButtons[j];

        emptyButton.addEventListener('click', function (e) {

            clearTimeout(tooltipTimer);

            var buttonRect = e.target.getBoundingClientRect();          

            tooltipFB.classList.remove('is-active');

            tooltipFB.style.bottom = (soundmachineContainer.getBoundingClientRect().bottom -  buttonRect.top + 24) + 'px';
            tooltipFBArrow.style.right = (soundmachineContainer.getBoundingClientRect().right - buttonRect.right - 20) + "px";

            void tooltipFB.offsetWidth;
            tooltipFB.classList.add('is-active');

            tooltipTimer = setTimeout(function(){ 
                tooltipFB.classList.remove('is-active');
                tooltipFB.style.bottom = "";
             }, 5000);

            document.addEventListener('click', function(event){
                console.log(event.target.closest('.tooltip--facebookhint'));
                    if(event.target.closest('.tooltip--facebookhint') == null && event.target.closest('button.empty') == null) {
                        tooltipFB.classList.remove('is-active');
                        tooltipFB.style.bottom = "";
                    }        
            });
            
        });

    }

    var showOverlay = function () {
        var overlay = document.querySelector('#overlay');
        overlay.style.display = "block";
    }

    var toggleHelp = document.getElementById("toggleHelp");

    if (toggleHelp) {
        toggleHelp.addEventListener('click', showOverlay, false);
    }

    // Hide Overlay
    var close_button = document.getElementById("close_overlay");
    if(close_button) {
        close_button.addEventListener('click', function() {
            document.querySelector("#overlay").style.display = 'none';
        });
    }

    var mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        loop: false,
    
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      });

    // Show Overlay
    var tooltip = document.getElementById("tooltip");

    var standalone = false;
    var body = document.getElementsByTagName("BODY")[0];

    if(("standalone" in window.navigator) && window.navigator.standalone){
        standalone = true;
        body.classList.add('standalone');

    } else if (window.matchMedia("(display-mode: standalone)").matches){
        standalone = true;
        body.classList.add('standalone');
    }

    if(tooltip){
        tooltip.addEventListener('click', showOverlay, false);

        // Only show tooltip if not in standalone mode
        if(standalone == false){
            // Hide tooltip after 5 seconds
            document.addEventListener("DOMContentLoaded", function () {
                setTimeout(
                    function () {
                        document.getElementById("tooltip").classList.add('is-visible');
                        document.getElementById("tooltip").classList.remove('is-hidden');
                    }, 5000);
                setTimeout(
                    function () {
                        document.getElementById("tooltip").classList.remove('is-visible');

                        setTimeout(
                            function () {
                                document.getElementById("tooltip").classList.add('is-hidden');
                            }, 800);

                    }, 10000);
                
            });
        }
    }

})();


